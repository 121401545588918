<template>
  <button class="calendar-cell" :data-date="date.day" 
  :class="rootClasses">{{ date.day }}</button>
</template>

<script>
export default {
  name: "CalendarCell",
  inheritAttrs: false,
  props: {
    date: {
      required: true,
      type: Object
    },
    selectedDates: {
      type: Array
    },
    isSelected: {
      default: false,
      type: Boolean
    },
    isDisabled: {
      default: false,
      type: Boolean
    },
    isBetween: {
      default: false,
      type: Boolean
    },
    isOneDayBefore: {
      default: false,
      type: Boolean
    },
    isOneDayAfter: {
      default: false,
      type: Boolean
    },
    isLast: {
      default: false,
      type: Boolean
    },
    isFirst: {
      default: false,
      type: Boolean
    },
    isOneDayBeforeFirst: {
      default: false,
      type: Boolean
    },
    isOneDayBeforeLast: {
      default: false,
      type: Boolean
    },
    isToday: {
      default: false,
      type: Boolean
    }
  },
  methods: {
  },
  computed: {
    rootClasses() {
      return {
        "--is-other-month-day": this.date.isOtherMonthDay,
        "--selected": this.isSelected,
        "--weekend": this.date.isWeekend,
        // "--disabled": this.isDisabled,
        "--isToday": this.date.isToday,
        "--between": this.isBetween,
        "--first": this.isFirst,
        "--last": this.isLast,
        "--one-day-before": this.isOneDayBefore,
        "--one-day-after": this.isOneDayAfter,
        "--one-day-before-first": this.isOneDayBeforeFirst,
        "--one-day-before-last": this.isOneDayBeforeLast
      };
    }
  }
};
</script>

<style scoped lang="scss">
$cell-width: 40px;
$cell-height: 40px;
$radius: 3px;

$color-primary: #387FBF;
$color-accent: #FF8A00;
$light-gray: #2c2c91;

.calendar-cell {
  border: none;
  appearance: none;
  margin: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: $cell-height;
  width: $cell-width;
  font-size: 16.1215px;
  font-weight: normal;
  cursor: pointer;
  position: relative;
  color: #484A4F;
  background-color: #F4F5F7;

  &.--between {
    background-color: $light-gray;
    border-radius: 0;
  }
  // &.--weekend {
  //   color: red;
  // }
  &.--is-other-month-day:not(.--disabled):not(.--between):not(.--selected) {
    opacity: 0.25;
    background-color:transparent;
  }
   &.--selected {
      background: #F7921C;
      border-radius: 2.68692px;
      color: #FFF;
    }
  &:focus:not(.--selected) {
    background: #F7921C;
    border-radius: 2.68692px;
    color: #FFF;
  }
   &:not(.--disabled):not(.--selected):hover {
      background: #F7921C;
      border-radius: 2.68692px;
      color: #FFF;
    }
  &.--between:focus {
    background-color: darken($light-gray, 3%);
  }
  &.--selected:focus {
    background: #F7921C;
    border-radius: 2.68692px;
    color: #FFF;
  }
  &.--isToday {
      border-radius: 2.68692px;
      border: 1.34346px solid #F7921C;
  }
}
</style>
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('RenderlessCalendar',{staticClass:"notranslate",attrs:{"locale":_vm.currentLocale,"mode":"single","default-selected-dates":[_vm.defaultDateFromApi ? _vm.defaultDateFromApi : '']},on:{"onDateChange":_vm.handleDateChange},scopedSlots:_vm._u([{key:"default",fn:function({
    getModifiers,
    selectedDates,
    currentYear,
    prevPage,
    nextPage,
    weekDayNames,
    onDateSelect,
    monthNames,
    calendar,
  }){return [_c('div',{staticClass:"root"},_vm._l((calendar),function(view){return _c('div',{key:`${view.month}-${view.year}`,staticClass:"calendar",attrs:{"data-date":selectedDates[0] && selectedDates[0].formatted}},[_c('div',{staticClass:"calendar__header"},[_c('button',{staticClass:"calendar__month-btn",on:{"click":prevPage}},[_c('img',{attrs:{"src":require("../../assets/arrow__forward.svg"),"alt":"img"}})]),_c('span',{staticClass:"calendar__title",staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(monthNames[view.month].full)+" "+_vm._s(view.year))]),_c('button',{staticClass:"calendar__month-btn",on:{"click":nextPage}},[_c('img',{attrs:{"src":require("../../assets/__arrow__back.svg"),"alt":"img"}})])]),_c('div',{staticClass:"calendar__weeks"},_vm._l((weekDayNames),function(day){return _c('span',{key:day.short,staticClass:"calendar__week-day"},[_vm._v(_vm._s(day.short))])}),0),_c('div',{staticClass:"calendar__body"},_vm._l((view.dates),function(date){return _c('CalendarCell',_vm._b({key:date.ms,attrs:{"date":date},nativeOn:{"click":function($event){return onDateSelect(date)}}},'CalendarCell',getModifiers(date),false))}),1)])}),0)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }
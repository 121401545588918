<template>
    <div class="">
        <!-- {{ goStraightToEditValue }} -->
        <div class="modal-header d-flex justify-content-between  pt-4 pb-2">
            <h5 class="modal-title " id="exampleModalLabel">
                {{ payload.selectedMessage.title }}
            </h5>
            <button class="__delete__bt_" @click="deleteTemplate"><img width="18px"
                    src="../../../assets/__delete_danger.svg" alt="delete icon"> &nbsp; Delete</button>
        </div>
        <div class="modal-body m-0 p-0">
            <div class="col-md-12 mt-4">
                <p>{{ payload.selectedMessage.body }}</p>
                <button v-if="!showSenderID && !showEditButtonVoice && payload.selectedMessage.type != 4" class="__btn__edit"
                    @click="editTemplate">
                    Edit
                </button>
            </div>
            <div class="col-md-12 mt-4" v-if="payload.messageType == '__scheduled'">
                <p class="text__">Message schedule to be delivered on</p>
                <div class="selected_date ">
                    <p class="_seletedDate_text">
                        {{
                            payload.selectedMessage.date_time ? moment(payload.selectedMessage.date_time).format('LL') :
                            ""
                        }}
                        at {{ payload.selectedMessage.date_time.split(" ")[1] }}
                        <!-- {{formData.hour ? "at" : ""}} {{formData.hour}}:{{formData.minute}}:{{formData.period}} -->
                    </p>
                </div>
            </div>
            <div class="col-md-12" v-if="showSenderID">
                <el-form :model="formData" :rules="rules" ref="ruleForm" @submit.prevent.native="checkEnter">
                    <div class="form_input_container">
                        <el-form-item label="Select Sender ID" prop="sender_id">
                            <el-select style="width:100%" @change="handleInputChange($event, 'sender_id')"
                                :value="senderIdFromStore" filterable placeholder="Select a Sender ID">
                                <el-option v-for="(senderid, key) in senderIDs" :key="key" :label="senderid.sender_id"
                                    :value="senderid.id" class="notranslate">
                                    <div style="width:100%" class="d-flex justify-content-between">
                                        <div> {{ senderid.sender_id }}</div>
                                        <img style="margin-left:2em;  width:18px; height:18px"
                                            src="@/assets/Shield Done.svg" alt="shield icon">
                                    </div>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <div class="col-md-12 ___ft_btns">
                <button class="__ft__cancel" @click="hideMainModal">{{ showSenderID ? 'Back' : 'Cancel' }}</button>
                <button class="__sch" v-if="payload.messageType == '__scheduled'" @click="scheduledMessage">change Schedule</button>
                <button class="__ft_send" v-if="payload.messageType !== '__scheduled' && !showSenderID"
                    @click="checkIfInternational">Send</button>
                <!-- showSenderID = true -->
                <button class="__ft_send" v-if="payload.messageType == '__scheduled'" @click="hideMainModal">Done</button>
                <button class="__ft_send" @click="sendMessage('ruleForm')" v-if="showSenderID">Continue</button>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/state/store.js'
import loginType from '@/helpers/loginType.js'
export default {
    name: 'view-message',
    props: {
        payload: {
            required: true,
            type: Object
        }
        , sendStraightToSenderId: {
            required: false,
            type: Function,
            default: false
        }
    },
    data() {
        return {
            showEditInModal: true,
            goStraightToEditValue: false,
            showSenderID: false,
            rules: {
                sender_id: [
                    { required: true, message: 'Sender ID is required', trigger: 'change' },
                ]
            },
            formData: {
                sender_id: "",
                perMessage: 0
            },
            sendToSenderID: this.sendStraightToSenderId,
        }
    },
    computed: {
        senderIDs() {
            return store.getters['senderid/senderIDs']
        },
        senderIdFromStore() {
            return store.state.modal.formData.sender_id
        },
        setStraightToEdit() {
            this.goStraightToEditValue = this.$props.payload.goStraightToEdit
        },
        showEditButtonVoice() {
            return loginType() === 'voice_messages' && this.$props.payload.messageType === '__scheduled'
        }
    },

    methods: {
        handleInputChange(value, key) {
            store.dispatch('modal/updateSingleField', {
                key, value
            })
            this.formData.sender_id = value
        },
        hideMainModal() {
            if (this.showSenderID) {
                this.showSenderID = false
                return;
            }
            store.dispatch('uistate/setIsInternational', false)
            this.$emit('closeModal')
        },
        scheduledMessage() {
            return store.dispatch('uistate/addContactFormData', {
                title: "Reschedule message",
                type: 'calender',
                isEdit: true,
                editType: "editMessage",
                editData: {
                    selectedKey: this.payload.selectedKey,
                    selectedMessage: this.payload.selectedMessage
                }
            })
        },
        editTemplate() {
            const newFormData = store.state.modal.formData
            if (this.payload.messageType == '__scheduled') {
                store.dispatch('uistate/addContactFormData', {
                    title: "Edit Message",
                    type: 'sendMessage',
                    isEdit: true,
                    editData: {
                        selectedKey: this.payload.selectedKey,
                        selectedMessage: this.payload.selectedMessage
                    }
                })

                if (!newFormData.title && !newFormData.message) {
                    store.dispatch('modal/addFormData', {
                        currentPage: 'sendMessage',
                        nextPage: '',
                        prevPage: '',
                        formData: {
                            id: this.payload.selectedMessage.id,
                            body: this.payload.selectedMessage.body,
                            title: this.payload.selectedMessage.title,
                            type: this.payload.selectedMessage.type,
                            isEdit: true,
                            pageTitle: 'Edit Message',
                            selectedKey: this.payload.selectedKey,
                        }
                    })
                }

            } else {
                store.dispatch('uistate/addContactFormData', {
                    title: "Edit template",
                    type: 'template',
                    isEdit: true,
                    editData: {
                        selectedKey: this.payload.selectedKey,
                        selectedMessage: this.payload.selectedMessage
                    }
                })
                if (!newFormData.title && !newFormData.message) {
                    store.dispatch('modal/addFormData', {
                        currentPage: 'template',
                        nextPage: '',
                        prevPage: '',
                        formData: {
                            id: this.payload.selectedMessage.id,
                            body: this.payload.selectedMessage.body,
                            title: this.payload.selectedMessage.title,
                            type: this.payload.selectedMessage.type,
                            isEdit: true,
                            pageTitle: 'Edit template',
                            selectedKey: this.payload.selectedKey,
                        }
                    })
                }
            }

        },
        deleteTemplate() {
            let dataPayload = {
                currentModal: 'delete',
                selectedKey: this.payload.selectedKey,
                selectedMessage: this.payload.selectedMessage
            }
            this.$emit('deleteFromView', dataPayload)
            store.dispatch('uistate/addContactFormData', {
                title: "delete",
                type: 'delete',
            })
        },
        sendMessage(formName) {
            let message = this.payload.selectedMessage
            if (message.body.length <= 160) {
                this.formData.perMessage = 1
            }
            else {
                if (message.body.length > 160) {
                    this.formData.perMessage = Math.ceil(message.body.length / 153)
                }
            }
            // else if (message.body.length > 160 && message.body.length <= 320) {
            //     this.formData.perMessage = 2
            // } else if (message.body.length > 320 && message.body.length <= 460) {
            //     this.formData.perMessage = 3
            // }
            store.dispatch('modal/setFromView', true)
            //prepare payload 
            let payload = {
                campaign_name: message.title,
                message: message.body,
                sender_id: this.formData.sender_id,
                perMessage: this.formData.perMessage
            }
            this.$refs[formName].validate((valid) => {
                if (valid || this.senderIdFromStore) {
                    this.$emit('goToSelectUsers', payload)
                } else {
                    return false;
                }

            });
        },
        checkIfInternational() {
            if (this.$props.payload.selectedMessage.type == 4) {
                let message = this.payload.selectedMessage
                store.dispatch('modal/setFromView', true)
                //prepare payload 
                let payload = {
                    campaign_name: message.title,
                    message: message.body,
                    sender_id: message.sender_id.id,
                    perMessage: this.formData.perMessage
                }
                // Per SMS is not shown because calculations have not been done when international
                store.dispatch('uistate/setIsInternational', true)
                this.$emit('goToSelectUsers', payload)
            }
            else {
                return this.showSenderID = true
            }
        }

        // checkMessageContent() {
        //     const getFormData = store.getters['modal/getFormData']
        //     if (getFormData.body.includes("[fname]", "[fullname]", "[lname]")) {
        //         store.dispatch('uistate/setIsFromTemplateWithSpecial', true)
        //     }
        // },
    },
    beforeMount() {
        if (store.state.uistate.contactForm && store.state.uistate.contactForm.fromSenderId) {
            this.showSenderID = true
        }

    },
    watch: {
        setStraightToEdit: {
            immediate: true,
            deep: true,
            handler() {
                if (this.goStraightToEditValue == true) {
                    this.editTemplate()
                    this.$props.payload.goStraightToEdit = false
                    store.dispatch('uistate/setCancelDirectEdit', true)
                }
            },
        },
    }
}

</script>

<style scoped>
.__delete__bt_ {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #FF5656;
    border: none;
    outline: none;
    background: transparent;
}

.__btn__edit {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #F7921C;
    background: transparent;
    outline: none;
    border: none;
    padding-left: 0px;
    margin-left: 0px;
}

.___ft_btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2em;
    margin-bottom: 2em;
}

.___ft_btns button {
    width: 123px;
    height: 40px;
    outline: none;
    border: none;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
}

.__ft__cancel {
    color: #9E9EA7;
    background: #F5F5F5;
    width: 70px !important;
}

.__ft_send {
    background: #F7921C;
    color: #FFFFFF
}

._seletedDate_text {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #F7921C;
    margin-bottom: 0px;
}

.text__ {
    font-size: 12px;
    text-align: center;
}

.__sch {
    width: 150px !important;
    color: #9E9EA7;
    background: #F5F5F5;
}
</style>
<template>
  <div class="" >
    <div class="modal-header d-flex justify-content-between  pt-4 pb-2">
        <h5 class="modal-title " id="exampleModalLabel">
            {{ $t("dashboard.create_voice_message.voice") }}
        </h5>
        <!-- <button class="__delete__bt_" @click="deleteVoice"><img width="18px" src="../../../assets/__delete_danger.svg" alt=""> &nbsp; Delete</button> -->
    </div>
    <div class="modal-body m-0 p-0">
        <div class="col-md-12 mt-4">
            <div class="__audio_container">
                <!-- <div class="__icon">
                    <button class="__play_pause" v-if="audioState == 'pause'" @click="playAudio" >
                        <img width="35px" height="35px"  
                        src="@/assets/pause-button-svgrepo-com (1).svg" alt="">
                    </button>
                    <button class="__play_pause" v-if="audioState == 'play'" @click="playAudio">
                       <img width="35px" height="35px"  
                        src="@/assets/play_light.svg" alt=""> 
                    </button>
                </div>
                <div class="__audio__controls">
                    <div class="status">
                        {{audioState == "play" ? "Playing" : "Paused"}}
                    </div>
                    <div class="time">
                        <p>
                            {{ currentTime }}
                        </p>
                        <p>
                            {{ audioDuration }}
                        </p>
                    </div>
                    <div class="_progress_bar">
                       <div class="_progress">
                           <div class="music_progress">

                           </div>
                       </div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="col-md-12 text-center">
             <audio controls id="audio-player" 
                    ref="audio"
                    :src="`${audioNote}`" >
            </audio>
            <!-- {{ audioNote }} -->
        </div>
        <div class="col-md-12 ___ft_btns">
            <button class="__ft__cancel"  @click="hideMainModal">{{ $t("misc.button.close") }}</button>
            <!-- <button class="__ft_send" @click="sendMessage" v-if="!hideSendMessage">Send Message</button> -->
        </div>
    </div>
  </div>
</template>

<script>
import store from '@/state/store.js'
import messageModal from '../messages/messageModal.vue'
export default {
    name:'view-message', 
    components: {
        messageModal
    },
    props: {
        payload: {
            type: Object
        }
    },
    data() {
        return {
            showSenderID: false,
            audioState: "pause",
            pauseTitle: 'play',
            currentTime: '2:00',
            toTalTime: '3:00',
            hideSendMessage:false
        }
    },
    mounted() {
        const audio = document.querySelector('#audio-player');
        document.querySelector(".modal").addEventListener("click", () => {
           audio.pause()
        })
    },
    computed: {
        senderIDs(){
            return store.getters['senderid/senderIDs']
        },
        audioNote() {
            let data = store.getters['uistate/contactForm']
            this.hideSendMessage = data.message.audio_path ? true : false
            return data.message.body || data.message.audio_path
        },
        audioDuration() {
           const audio = document.querySelector('audio');
           return audio ? this.calculateTime(audio.duration)  : "0:00"
        }
    },
    methods: {
        calculateTime(secs){
        const minutes = Math.floor(secs / 60);
        const seconds = Math.floor(secs % 60);
        const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
        return `${minutes}:${returnedSeconds}`;
        },
        playAudio() {
            let audio = this.$refs.audio
            if(this.audioState === 'pause') {
                const audio = document.querySelector('audio');
                audio.play()
                this.audioState = "play"
            } else {
               const audio = document.querySelector('audio');
               audio.pause()
               this.audioState = "pause"
            }
        },
        hideMainModal(){
            this.$emit('closeModal')
            const audio = document.querySelector('audio');
            audio.pause()
            this.audioState = "pause"
        },
        deleteVoice(){
            let data = store.getters['uistate/contactForm']
            let dataPayload = {
                currentModal: 'delete',
                selectedKey: data.key,
                deleteType: 'voice',
                selectedMessage: data.message,
                deleteMessage: 'Deleting Voice Message will remove it permenantly.'
            }
            store.dispatch('uistate/addContactFormData', {
                title: "",
                type:'delete',
                message: dataPayload
            })
        },
        sendMessage(){
            let data = store.getters['uistate/contactForm']
            let payload = {
                campaign_name: data.message.title,
                audio:  data.message.body,
                isScheduleVoice: true,
                fromSelectSenderIDForVoice: true,
                fromVoice: true
            }
            store.dispatch('uistate/addContactFormData', {
                title: "Select sender ID",
                type:'selectSenderId',
                message: payload
            })  
        }
    }
}
</script>

<style lang="scss" scoped>
.__play_pause{
    outline: none;
    border: none;
    background-color: transparent;
}
.__audio_container{
    background: #F3F3F4;
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    .__icon{
        width: 50px;
        padding:1em;
    }
    .__audio__controls{
        width: calc(100% - 50px);
        padding-right: 1em;
        padding-left: 1em;
        .status{
            font-size: .9em;
            padding-top:0px;
            margin:0px;
            margin-bottom:.3em;
        }
        .time{
            display: flex;
            justify-content: space-between;
            p{
                margin:0px!important;
                font-size: .8em;
            }
        }
        ._progress_bar{
            padding-top:.2em;
            ._progress{
                width:100%;
                height: 4px;
                background-color: #D4D8E2;
                border-radius: 2px;
                position: relative;
            }
            .music_progress{
                position: absolute;
                top: 0;
                bottom:0;
                right:0;
                left: 0;
                z-index: 100;
                border-radius: 2px;
                background-color: #F7921C;
                width: 50%;
            }
        }
    }
}

.__delete__bt_{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #FF5656;
    border:none;
    outline:none;
    background: transparent;
}
.__btn__edit{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #F7921C;
    background: transparent;
    outline: none;
    border:none;
    padding-left: 0px;
    margin-left: 0px;
}
.___ft_btns{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top:2em;
    margin-bottom: 2em;
}
.___ft_btns button{
    width:123px;
    height: 40px;
    outline: none;
    border:none;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    }
.__ft__cancel{
    color: #9E9EA7;
    background: #F5F5F5;
    width:70px!important;
}
.__ft_send{
     color: #9E9EA7;
    background: #F5F5F5;
   
}
._seletedDate_text{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #F7921C;
    margin-bottom: 0px;
}
.text__{
    font-size: 12px;
    text-align: center;
}
.__sch{
    width:150px!important;
    color: #9E9EA7;
    background: #F5F5F5;
}
</style>
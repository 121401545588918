<template>
  <div
    class="row justify-content-center"
    style="padding-right: 1em; padding-left: 1em">
    <div class="col-md-12" v-show="displayType === 'template'">
      <el-form
        :model="formData"
        :rules="rules"
        ref="ruleForm"
        @submit.prevent.native="checkEnter"
        class=""
        :scroll-to-error="true">
        <div
          class="row justify-content-center main-form-rule"
          v-show="!createSenderID">
          <div class="col-md-12">
            <div class="form_input_container">
              <el-form-item
                :label="
                  $t(
                    'dashboard.send_message.message_templates.form.title.label'
                  )
                "
                prop="title">
                <el-input
                  type="text"
                  autocomplete="off"
                  :placeholder="
                    $t(
                      'dashboard.send_message.message_templates.form.title.placeholder'
                    )
                  "
                  v-model="formData.title"
                  @change="handleInputChange($event, 'title')" />
              </el-form-item>
            </div>
          </div>
          <div class="col-md-12">
            <p style="margin: 0px">
              <el-form-item
                style="margin: 0px"
                :label="
                  $t(
                    'dashboard.send_message.message_templates.form.content.label'
                  )
                "
                prop="body"
                :show-message="false">
              </el-form-item>
            </p>
            <div class="content_style">
              <div @click="insertText('[fname]')">
                [
                {{
                  $t(
                    "dashboard.send_message.message_templates.form.content.first_name"
                  )
                }}
                ]
              </div>
              <div @click="insertText('[fullname]')">
                [
                {{
                  $t(
                    "dashboard.send_message.message_templates.form.content.full_name"
                  )
                }}
                ]
              </div>
              <div @click="insertText('[lname]')">
                [
                {{
                  $t(
                    "dashboard.send_message.message_templates.form.content.last_name"
                  )
                }}
                ]
              </div>
            </div>
            <div class="form_input_container">
              <el-form-item style="margin-top: 0px" prop="body">
                <el-input
                  @keyup.enter.native="handleCharacterEncoding"
                  type="textarea"
                  :rows="4"
                  autocomplete="off"
                  ref="message"
                  :placeholder="
                    $t(
                      'dashboard.send_message.message_templates.form.message.placeholder'
                    )
                  "
                  v-model="formatASCII"
                  @change="handleInputChange($event, 'body')"
                  id="textarea"></el-input>
              </el-form-item>
            </div>
            <div class="use-gsm">
              <div class="info-gsm-only">
                <el-button
                  @click="showGSM_Only_Info = true"
                  size="small"
                  :title="
                    $t(
                      'dashboard.send_message.message_templates.form.message.learn_more'
                    )
                  "
                  class="info-gsm-button">
                  <el-icon class="info-icon" name="info"></el-icon
                ></el-button>
              </div>
              <div class="check-gsm-only">
                <el-checkbox
                  :label="
                    $t(
                      'dashboard.send_message.message_templates.form.message.gsm_only'
                    )
                  "
                  v-model="useGSMOnly"></el-checkbox>
              </div>

              <div class="info-gsm-only" style="margin-right: 2rem">
                <AIStars
                  :campaign="formData"
                  @ai-closed="handleAIClosed"
                  :before-open="handleAIBeforeOpen"
                  is-create-template />
              </div>
            </div>
            <div class="d-flex">
              <p class="text_description">
                {{ formData.body.length }}
                {{
                  $t(
                    "dashboard.send_message.message_templates.form.message.characters_entered"
                  )
                }}
              </p>
              <p class="text_description pl-4" v-if="findNumberOfRecipient">
                {{ findNumberOfRecipient }}
                {{
                  $t(
                    "dashboard.send_message.message_templates.form.message.per_recipient"
                  )
                }}
              </p>
              <div class="text_description pl-4" v-if="findNumberOfRecipient">
                {{
                  $t(
                    "dashboard.send_message.message_templates.form.message.encoding_type"
                  )
                }}

                <span
                  v-if="
                    getEncoding == 'GSM_7BIT' || getEncoding == 'GSM_7BIT_EX'
                  "
                  style="color: #36d962"
                  >GSM_7BIT</span
                >
                <span v-else style="color: red">{{ getEncoding }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form_input_container">
              <el-form-item
                :label="
                  $t(
                    'dashboard.send_message.message_templates.form.message.message_category'
                  )
                "
                prop="type">
                <el-select
                  style="width: 100%"
                  v-model="formData.type"
                  @change="handleInputChange($event, 'type')"
                  filterable
                  :placeholder="
                    $t(
                      'dashboard.send_message.message_templates.form.message.select_category'
                    )
                  ">
                  <el-option
                    v-for="(category, key) in message_category"
                    :key="key"
                    :label="category.name"
                    :value="category.id">
                    <div class="category-list-options">
                      <span>{{ category.name }}</span>
                      <!-- <span v-if="category.name == 'International'" class="is-new">New</span> -->
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="
                  $t(
                    'dashboard.send_message.message_templates.form.message.select Countries'
                  )
                "
                prop="selected_countries"
                v-if="formData.type == '4'">
                <el-select
                  style="width: 100%"
                  v-model="formData.selected_countries"
                  multiple
                  filterable
                  :placeholder="
                    $t(
                      'dashboard.send_message.message_templates.form.message.select Countries'
                    )
                  "
                  class="multiple-select"
                  :collapse-tags="true"
                  :collapse-tags-tooltip="true"
                  :max-collapse-tags="5"
                  :title="formData.selectedCountries">
                  <el-option
                    v-for="(country, key) in selectedCountries"
                    :key="key"
                    :label="country.name"
                    :value="country.name">
                    <div class="category-list-options">
                      <span>{{ country.name }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="col-md-12" v-if="formData.type == '4'">
            <div class="form_input_container __create" id="__create">
              <div :class="[corporate ? '_select' : '___total_width']">
                <el-form-item
                  :label="$t('dashboard.overview.add_sender_id')"
                  prop="sender_id">
                  <el-select
                    style="width: 100%"
                    @change="handleInputChange($event, 'sender_id')"
                    v-model="formData.sender_id"
                    filterable
                    :placeholder="
                      $t('dashboard.send_message.select_sender_id')
                    ">
                    <el-option
                      class="notranslate"
                      v-for="(senderid, key) in senderIDs"
                      :key="key"
                      :label="senderid.sender_id"
                      :value="senderid.id"
                      :disabled="!senderid.status == 1">
                      <div
                        style="width: 100%"
                        class="d-flex justify-content-between notranslate">
                        <div class="notranslate">{{ senderid.sender_id }}</div>
                        <img
                          v-if="senderid.status == 1"
                          style="margin-left: 2em; width: 18px; height: 18px"
                          src="@/assets/Shield Done.svg"
                          alt="shield icon" />
                        <img
                          v-if="senderid.status == 0"
                          style="margin-left: 2em; width: 18px; height: 18px"
                          src="@/assets/Shield_pending.svg"
                          alt="shield icon" />
                        <img
                          v-if="senderid.status == 2"
                          style="margin-left: 2em; width: 18px; height: 18px"
                          src="@/assets/shield_declined.svg"
                          alt="shield icon" />
                        <img
                          v-if="senderid.status == 3"
                          style="margin-left: 2em; width: 18px; height: 18px"
                          src="@/assets/shield_on_hold.svg"
                          alt="shield icon" />
                      </div>
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="button">
                <el-button @click="createSenderID = true">{{
                  $t(
                    "dashboard.send_message.message_templates.form.message.create_sender_id"
                  )
                }}</el-button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row justify-content-center is-sender-id"
          v-if="createSenderID">
          <div class="col-md-12">
            <div class="form_input_container">
              <el-form-item
                :label="$t('dashboard.sender_id.label')"
                prop="sender">
                <el-input
                  type="text"
                  autocomplete="off"
                  :placeholder="$t('dashboard.sender_id.placeholder')"
                  v-model="formData.sender"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form_input_container">
              <el-form-item
                :label="$t('dashboard.sender_id.purpose')"
                prop="purpose">
                <el-input
                  type="textarea"
                  :rows="4"
                  autocomplete="off"
                  :placeholder="$t('dashboard.sender_id.placeholder_purpose')"
                  v-model="formData.purpose"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="col-md-12" v-if="openSenderIDError">
            <Alert :message="openSenderIDError" :type="type" />
          </div>
          <div class="col-md-12 d-flex justify-content-between mb-5 mt-3">
            <button
              class="cancel mr-2 notranslate"
              @click="createSenderID = false">
              {{ $t("misc.button.cancel") }}
            </button>
            <el-button
              style="padding-top: 1.1em; padding-bottom: 1.1em"
              type="primary"
              :loading="loading"
              @click="submit('ruleForm', 'createSenderID')"
              >create</el-button
            >
          </div>
        </div>
        <span v-if="errors">
          <Alert :message="errors" :type="type" />
        </span>
      </el-form>
    </div>
    <div
      class="col-md-12 mt-3 mb-4 d-flex justify-content-between"
      style="width: 100%"
      v-if="!createSenderID">
      <button class="___back__btn" v-if="!isEdit" @click="back('cancel')">
        {{ $t("misc.button.close") }}
      </button>
      <button
        class="___back__btn"
        v-if="isEdit && !getGoBackDirectly"
        style="padding-top: 1.1em; padding-bottom: 1.1em"
        @click="back('back')">
        {{ $t("misc.button.back") }}
      </button>
      <!-- Go back Directly -->
      <button
        class="___back__btn notranslate"
        v-if="getGoBackDirectly"
        style="padding-top: 1.1em; padding-bottom: 1.1em"
        @click="back('cancel')">
        {{ $t("misc.button.cancel") }}
      </button>

      <div class="left_btn" v-if="!showSenderID">
        <!-- <button  class="save_and_send mr-2"    v-if="!isEdit" @click="showSenderID = true">Save and send</button> -->
        <el-button
          v-if="!isEdit"
          style="padding-top: 1.1em; padding-bottom: 1.1em"
          type="primary"
          :loading="loading"
          @click="submit('ruleForm', 'save')"
          >{{ $t("misc.button.save") }}</el-button
        >
        <el-button
          v-if="isEdit"
          style="padding-top: 1.1em; padding-bottom: 1.1em"
          type="primary"
          :loading="loading"
          @click="submit('ruleForm', 'edit')"
          >{{ $t("misc.button.edit") }}</el-button
        >
      </div>
      <div class="left_btn" v-if="showSenderID">
        <button
          class="cancel mr-2 notranslate"
          v-if="!isEdit"
          @click="showSenderID = false">
          {{ $t("misc.button.cancel") }}
        </button>
        <el-button
          style="padding-top: 1.1em; padding-bottom: 1.1em"
          type="primary"
          :loading="loading"
          @click="submit('ruleForm', 'sendAndSave')"
          >{{ $t("misc.button.continue") }}</el-button
        >
      </div>
    </div>
    <el-dialog
      :visible.sync="showGSM_Only_Info"
      :title="$t('dashboard.send_message.message_templates.notes.title')"
      class="main-modal"
      :modal="false"
      :top="'3rem'"
      :width="dynamicModalWidth">
      <div class="modal-header">
        <h4 class="modal-title">
          {{ $t("dashboard.send_message.message_templates.notes.title") }}
        </h4>
      </div>
      <div class="modal-body">
        <div class="long-form-sms">
          <h5>
            <b>{{
              $t(
                "dashboard.send_message.message_templates.notes.long_sms_messages"
              )
            }}</b>
          </h5>
          <p>
            {{
              $t("dashboard.send_message.message_templates.notes.exceed_160")
            }}
          </p>
          <br />
          <p>
            {{ $t("dashboard.send_message.message_templates.notes.see_below") }}
          </p>
          <ul>
            <li>
              {{
                $t(
                  "dashboard.send_message.message_templates.notes.standard_gsm.character_counts.2_message_parts"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "dashboard.send_message.message_templates.notes.standard_gsm.character_counts.3_message_parts"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "dashboard.send_message.message_templates.notes.standard_gsm.character_counts.4_message_parts"
                )
              }}
            </li>
          </ul>
        </div>
        <div class="utf-16">
          <h5>
            <b>{{
              $t("dashboard.send_message.message_templates.notes.unicode.title")
            }}</b>
          </h5>
          <p>
            {{
              $t(
                "dashboard.send_message.message_templates.notes.unicode.description"
              )
            }}
          </p>
          <br />
          <p>
            {{
              $t("dashboard.send_message.message_templates.notes.unicode.note")
            }}
          </p>
          <br />
          <p>
            {{
              $t(
                "dashboard.send_message.message_templates.notes.unicode.see_below"
              )
            }}
          </p>
          <ul>
            <li>
              {{
                $t(
                  "dashboard.send_message.message_templates.notes.unicode.character_counts.2_message_segments"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "dashboard.send_message.message_templates.notes.unicode.character_counts.3_message_segments"
                )
              }}
            </li>
            <li>
              {{
                $t(
                  "dashboard.send_message.message_templates.notes.unicode.character_counts.4_message_segments"
                )
              }}
            </li>
          </ul>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="showGSM_Only_Info = false"
          >OK</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import $ from "jquery";
  import get_length_and_segment from "@/helpers/character_counter";
  import store from "@/state/store.js";
  import Storage from "@/services/storage.js";
  import ASCIIFolder from "fold-to-ascii";
  import allCountries from "@/helpers/allCountriesCode.json";
  import selectRoute from "./global/selectRoute.vue";
  import AIStars from "./ai/aiStars.vue";
  export default {
    name: "create-message-template",
    components: {
      selectRoute,
      AIStars,
    },
    data() {
      return {
        messages: "",
        allCountries: allCountries,
        createSenderID: false,
        displayType: "template",
        success: "",
        errors: "",
        modalType: "",
        messageTemplates: [],
        loadingTemplate: false,
        loading: false,
        type: "alert-danger",
        showSenderID: false,
        message_category: [
          {
            id: 1,
            name: "SMS",
          },
          {
            id: 3,
            name: this.$t(
              "dashboard.send_message.message_templates.form.message.birthday"
            ),
          },
          {
            id: 4,
            name: this.$t(
              "dashboard.send_message.message_templates.form.message.international"
            ),
          },
        ],
        rules: {
          type: [
            {
              required: true,
              message: this.$t(
                "dashboard.send_message.message_templates.form.message.select_category"
              ),
              trigger: "change",
            },
          ],
          title: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          body: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
            {
              max: 459,
              message: this.$t(
                "dashboard.send_message.message_templates.form.message.reduce_chars"
              ),
              trigger: ["change", "blur"],
            },
          ],
          sender_id: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          sender: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
            {
              max: 11,
              message: this.$t("dashboard.sender_id.min"),
              trigger: ["change", "blur"],
            },
          ],
          purpose: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          selected_countries: [
            {
              required: true,
              message: this.$t(
                "dashboard.send_message.message_templates.form.message.select_at_least_one_country"
              ),
              trigger: "change",
            },
          ],
        },
        formData: {
          type: "",
          title: "",
          body: "",
          id: "",
          sender_id: "",
          perMessage: 0,
          purpose: "",
          sender: "",
          selected_countries: [],
          route_name: "",
        },
        sendMessage: {},
        sendMessageRules: {},
        selectedKey: "",
        isEdit: false,
        openSenderIDError: "",
        encodingType: "",
        useGSMOnly: true,
        showGSM_Only_Info: false,
      };
    },
    mounted() {
      let editedData = store.getters["uistate/contactForm"];
      const getFormData = store.getters["modal/getFormData"];
      this.formData = {
        type:
          this.$route.name == "international messages" ? 4 : getFormData.type,
        title: getFormData.title,
        body: getFormData.body,
        id: getFormData.id,
        sender_id: "",
      };
      if (editedData.isEdit) {
        this.selectedKey = editedData.editData.selectedKey;
        this.isEdit = true;
        this.formData.sender_id =
          this.formData.type == 4
            ? editedData.editData.selectedMessage.sender_id.id
            : "";
      } else {
        this.isEdit = false;
      }
    },
    computed: {
      corporate() {
        const storage = new Storage();
        return storage.getSavedState("isCorporate");
      },
      findNumberOfRecipient() {
        let { body } = this.formData;
        if (body.length == 0) {
          return 0;
        }
        let { messages, encoding } = get_length_and_segment(body);
        this.formData.perMessage = messages;
        this.formData.encoding = encoding;
        this.encodingType = encoding;
        return messages;
      },
      senderIDs() {
        return store.getters["senderid/allSenderIDs"];
      },
      messageForm() {
        return store.getters["uistate/contactForm"];
      },
      groups() {
        return store.getters["groups/all_groups"];
      },
      templates() {
        return store.getters["campaigns/templates"];
      },
      getGoBackDirectly() {
        let editedData = store.getters["uistate/contactForm"];

        return editedData.goBackDirectly;
      },
      getEncoding() {
        return this.encodingType;
      },
      formatASCII: {
        get() {
          return this.formData.body;
        },
        set(value) {
          if (this.useGSMOnly) {
            this.formData.body = ASCIIFolder.foldReplacing(value);
          } else {
            this.formData.body = value;
          }
        },
      },
      dynamicModalWidth() {
        if (screen.width < 768) {
          return "98%";
        } else {
          return "50%";
        }
      },
      selectedCountries() {
        return this.allCountries;
      },
    },
    methods: {
      async handleAIBeforeOpen() {
        const response = await this.$refs.ruleForm.validate();
        if (typeof response === "boolean") {
          return true;
        }
        return false;
      },
      handleAIClosed($event) {
        if ($event) {
          this.formData.body = $event;
        }
      },
      handleInputChange(value, key) {
        store.dispatch("modal/updateSingleField", {
          key,
          value,
        });
      },
      handleCharacterEncoding(e) {
        // let { body } = this.formData
        // if( body.length == 0) {
        //     return 0
        // }
        // let {
        //     messages,
        // } = get_length_and_segment(body)
        // this.formData.perMessage = messages
        // return messages
      },
      createNewSenderID() {
        this.openSenderIDError = "";
        this.loading = true;
        let payload = {
          sender_id: this.formData.sender,
          purpose: this.formData.purpose,
        };
        store
          .dispatch("senderid/registerSenderID", payload)
          .then((response) => {
            const sender = response.data.data;
            if (sender.status == 1) {
              this.formData.sender_id = sender.id;
            }
            if (response) {
              this.loading = false;
              this.createSenderID = false;
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err.response?.data == undefined) {
              this.openSenderIDError = this.$t("misc.network_error");
            }
            if (err.response && err.response.status === 404) {
              if (err.response.data.data.sender_id) {
                return (this.openSenderIDError =
                  err.response.data.data.sender_id[0]);
              }
              this.openSenderIDError = err.response.data.data.error;
            }
          });
      },
      cancelSenderID() {
        this.showSenderID = false;
      },
      submit(formName, type) {
        this.loading = true;
        this.$refs[formName].validate((valid, invalidFields) => {
          if (valid) {
            if (type == "createSenderID") {
              return this.createNewSenderID();
            }
            if (type === "sendAndSave") {
              return this.saveAndSend();
            } else if (type === "save") {
              return this.save();
            } else {
              return this.edit();
            }
          } else {
            this.loading = false;
            // Get specific field errors
            // loop over invalidFields object
            for (const key in invalidFields) {
              // get the first error message
              const message = invalidFields[key][0].message;
              // log the field name and the error message
              setTimeout(() => {
                this.$notify.error({
                  title: this.$t("misc.toast.error"),
                  message: message,
                  position: "top-right",
                  duration: 5000,
                });
              }, 5);
            }
            return false;
          }
        });
      },
      save() {
        if (this.formData.selected_countries) {
          this.formData.selected_countries =
            this.formData.selected_countries.join(",");
        } else {
          this.formData.selected_countries = "";
        }

        store
          .dispatch("campaigns/saveTemplate", this.formData)
          .then((res) => {
            if (res) {
              if (res) {
                this.loading = false;
                this.$emit("saveTemplate", res.data);
              }
            }
          })
          .catch((err) => {
            this.loading = false;
            this.$notify({
              title: "Error",
              message:
                err.response.data.message ||
                "There was an issue creating the message template",
              type: "error",
              duration: 8000,
              showClose: false,
            });
            if (err?.response?.data == undefined) {
              this.errors = this.$t("misc.network_error");
            }
          });
      },
      edit() {
        store
          .dispatch("campaigns/editTemplate", {
            body: this.formData,
            key: this.selectedKey,
          })
          .then((res) => {
            if (res) {
              this.loading = false;
              let payload = {
                data: res.data.data,
                key: this.selectedKey,
                message: res.data.message,
              };
              this.$emit("editTemplate", payload);
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.errors = this.$t("misc.network_error");
            } else {
              this.$notify({
                title: this.$t("misc.toast.error"),
                message: err.response.data.message,
                type: "error",
              });
            }
          });
      },
      insertText(data) {
        // insert at cursor position
        var textarea = document.getElementById("textarea");
        var strPos = 0;
        var br =
          textarea.selectionStart || textarea.selectionStart == "0"
            ? "ff"
            : document.selection
            ? "ie"
            : false;
        if (br == "ie") {
          textarea.focus();
          var range = document.selection.createRange();
          range.moveStart("character", -textarea.value.length);
          strPos = range.text.length;
        } else if (br == "ff") strPos = textarea.selectionStart;

        var front = textarea.value.substring(0, strPos);
        var back = textarea.value.substring(strPos, textarea.value.length);
        this.formData.body = front + data + back;
        strPos = strPos + data.length;
        if (br == "ie") {
          textarea.focus();
          var range = document.selection.createRange();
          range.moveStart("character", -textarea.value.length);
          range.moveStart("character", strPos);
          range.moveEnd("character", 0);
          range.select();
        } else if (br == "ff") {
          textarea.selectionStart = strPos;
          textarea.selectionEnd = strPos;
          textarea.focus();
        }

        this.$refs.message.focus();
      },
      back(type) {
        this.showSenderID = false;
        this.$emit("closeModal", type);
      },
      saveAndSend() {
        let payload = {
          campaign_name: this.formData.title,
          message: this.formData.body,
          sender_id: this.formData.sender_id,
          perMessage: this.formData.perMessage,
        };
        this.$emit("goToSelectUsers", payload);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .___total_width {
    width: 100%;
  }

  .text_description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484a4f;
  }

  .save_and_send {
    background: #f5f5f5;
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 1.1em 0.9em;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #9e9ea7;
  }

  .__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .content_style {
    display: flex;
    justify-content: space-between;
    color: #f7921c;
    margin-bottom: 1em;
    font-size: 14px;

    div {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 480px) {
    .content_style {
      flex-wrap: wrap;
      font-size: 12px;
      text-align: center;
      justify-content: center;

      div {
        margin: 0.6em;
      }
    }
  }

  .main-form-rule {
    height: 72vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .main-form-rule::-webkit-scrollbar {
    width: 5px;
  }

  .category-list-options {
    display: flex;
    justify-content: space-between;
  }

  .is-new {
    background-color: #f56c6c;
    color: white;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    animation: new 1s ease;
    display: flex;
    width: 50px;
    justify-content: center;
  }

  .use-gsm {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-gsm-only {
    position: relative;
    right: 0;
    font-size: 12px;
    color: #1ce1f7;
    margin-left: 1rem;
  }

  .check-gsm-only {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 30px;
  }

  .check-gsm-only * {
    margin: auto 0;
  }

  .info-gsm-button {
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info-icon {
    font-size: 25px;
    color: #0ca2d8;
  }

  .main-modal {
    background-color: #0000008f;
  }

  .modal-body {
    height: 25rem;
    overflow-y: scroll;
  }

  .multiple-select * {
    border: 3px solid #f7921c !important;
    border-radius: 5px;
  }

  @keyframes new {
    30% {
      transform: scale(1.2);
    }

    40%,
    60% {
      transform: rotate(-20deg) scale(1.2);
    }

    50% {
      transform: rotate(20deg) scale(1.2);
    }

    70% {
      transform: rotate(0deg) scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }
</style>

<template>
  <div
    class="select-route-container"
    :class="{
      'full-width': fullWidth,
    }"
  >
    <div class="left-side">
      <el-select
        placeholder="Select a route"
        v-model="selectedRoute"
        @change="onRouteChange"
        :size="inputSize"
      >
        <el-option
          v-for="(route, index) in routeOptions"
          :key="index"
          :label="route.label"
          :value="route.value"
        ></el-option>
      </el-select>
    </div>
    <div class="right-side" v-if="showHelp">
      <el-tooltip content="Help">
        <el-button
          type="primary"
          round
          icon="el-icon-info"
          size="small"
          style="padding: 3px 4px; font-size: 15px; line-height: 0"
          @click="helpVisible = true"
        ></el-button>
      </el-tooltip>
      <el-dialog
        :visible.sync="helpVisible"
        title="Route Options"
        append-to-body
      >
        <div class="help-dialog">
          <ul>
            <li>
              <strong>Standard:</strong> <br />
              The standard route is automatically selected as the default
              option. Message costs are
              <strong style="color: #f56c6c"
                >deducted from your SMS/bonus balance,</strong
              >
              and it assigns normal priority to your messages.
            </li>
            <br />
            <li>
              <strong>High Priority:</strong><br />
              Opting for the High Priority route deducts the message cost
              <strong style="color: #f56c6c">
                directly from your wallet balance.</strong
              >
              This prioritizes your message at the top and involves a higher
              cost.
            </li>
          </ul>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import storage from "@/services/storage.js";
  import apiService from "@/services/api.service";
  export default {
    name: "selectRoute",
    emits: ["routeValue"],
    props: {
      inputSize: {
        type: String,
        default: "medium",
      },
      showHelp: {
        type: Boolean,
        default: false,
      },
      fullWidth: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        routeOptions: [
          { value: "standard", label: "Standard" },
          { value: "high_priority_route", label: "High Priority" },
        ],
        storage: new storage(),
        selectedRoute: "standard",
        helpVisible: false,
      };
    },
    methods: {
      async onRouteChange() {
        this.$emit("input", this.selectedRoute);
        this.storage.saveAndDelete("defaultRoute", this.selectedRoute);
        // save route
        try {
          await apiService.post(`api/save/default/route`, {
            route_name: this.selectedRoute,
          });
        } catch (error) {
          console.error(error);
        }
      },
    },
    async mounted() {
      if (this.storage.getSavedState("defaultRoute")) {
        this.selectedRoute = this.storage.getSavedState("defaultRoute");
        this.$emit("input", this.selectedRoute);
        return;
      }
      try {
        const { data } = await apiService.get(`api/get/default/route`);
        this.selectedRoute = data?.route_name || "standard";
        this.$emit("input", this.selectedRoute);
      } catch (error) {
        console.error(error);
      }
    },
  };
</script>

<style scoped>
  .select-route-container {
    display: flex;
    gap: 1rem;
  }
  .full-width {
    width: 100%;
  }
  .full-width .left-side,
  .full-width >>> .el-select {
    width: 100%;
  }


  @media screen and (max-width: 768px) {
    ::v-deep .el-dialog {
      width: 90% ;
    }
  }
</style>

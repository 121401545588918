<template>
  <div
    class="row justify-content-center"
    style="padding-right: 1em; padding-left: 1em">
    <div
      style="padding-right: 3em; padding-left: 3em"
      class="col-md-12 d-flex _ justify-content-center flex-column align-items-center"
      :lock-scroll="false">
      <div class="request_did" v-if="isSpecialRoute">
        <h6>
          TrueCaller may tag default voice SMS IDs with suggested names from
          call recipients, and we have no control over this;
        </h6>
        <h6>
          for a premium voice caller ID number (at GHS 100) with potential
          avoidance,<a
            href="#"
            target="_blank"
            class="flink"
            v-on:click.prevent="ConfirmationDialogVisible"
            >click here</a
          >
        </h6>
        <div class="confirmation-modal">
          <el-dialog
            :visible.sync="isConfirmationDialogVisible"
            :title="'Confirmation'"
            width="40%"
            :modal-append-to-body="false">
            <div class="heading" v-if="isConfirmationDialogVisible">
              <p>
                Are you sure you want to request for a Dedicated Voice Caller
                ID?
              </p>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button
                type="primary"
                @click="isConfirmationDialogVisible = false"
                >Cancel</el-button
              >
              <el-button
                type="danger"
                @click="submitChange"
                :loading="buttonLoading"
                >Confirm</el-button
              >
            </span>
          </el-dialog>
        </div>
      </div>

      <h3 class="__header">
        {{ title }}
      </h3>

      <button
        :class="[messageAction == 'individual' ? 'activeBtn' : '']"
        class="_btn"
        @click="sendToAnIndividual"
        v-if="showQuickSMS">
        {{ individual }}
      </button>
      <button
        :class="[messageAction == 'group' ? 'activeBtn' : '']"
        class="_btn"
        @click="sendToGroup">
        {{ groupTitle }}
      </button>
      <button
        :class="[messageAction == 'excel' ? 'activeBtn' : '']"
        class="_btn"
        @click="importContacts"
        v-if="showQuickSMS">
        {{ contacts }}
      </button>
    </div>
    <div class="col-md-12 mt-5 mb-5">
      <button class="cancel notranslate" @click="cancelModal">
        {{ fromView ? $t("misc.button.back") : $t("misc.button.cancel") }}
      </button>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";

  export default {
    name: "select-sender-id",
    data() {
      return {
        hovered: "",
        fromVoice: false,
        isFromQuickVoice: false,
        voiceData: {
          campaign_name: "",
          audio: "",
        },
        showQuickSMS: true,
        showModal: false,
        showConfirmationModal: false,
        buttonLoading: false,
        isConfirmationDialogVisible: false,
      };
    },
    mounted() {
      let isFromvoice = store.getters["uistate/fromVoice"];
      let isFromQuickVoice = store.getters["uistate/quickVoice"];
      if (isFromQuickVoice) {
        this.isFromQuickVoice = true;
      }
      if (isFromvoice) {
        this.fromVoice = true;
      }
      this.checkToggleShowOptions();
    },
    computed: {
      messageAction() {
        if (store.getters["uistate/getisFromTemplateWithSpecial"] === true) {
          this.sendToGroup();
          store.dispatch[("uistate/getisFromTemplateWithSpecial", false)];
        }
        return store.state.modal.messageAction;
      },
      fromView() {
        return store.state.modal.fromView;
      },
      checkIsVoice() {
        let isFromvoice = store.getters["uistate/fromVoice"];
        let isFromQuickVoice = store.getters["uistate/quickVoice"];
        return isFromvoice || isFromQuickVoice ? true : false;
      },
      title() {
        return this.checkIsVoice
          ? this.$t("dashboard.send_message.select")
          : this.$t("dashboard.send_message.what_action");
      },
      groupTitle() {
        return this.checkIsVoice
          ? this.$t("dashboard.send_message.voice.group_voice.title")
          : this.$t("dashboard.send_message.sms.group_sms.title");
      },
      individual() {
        return this.checkIsVoice
          ? this.$t("dashboard.send_message.voice.quick_voice.title")
          : this.$t("dashboard.send_message.sms.quick_sms.title");
      },
      contacts() {
        return this.checkIsVoice
          ? this.$t("dashboard.send_message.voice.excel_voice.title")
          : this.$t("dashboard.send_message.sms.excel_sms.title");
      },
      fromSelectSenderIDForVoice() {
        return store.getters["uistate/contactForm"].fromSelectSenderIDForVoice
          ? true
          : false;
      },
      isSpecialRoute() {
        return this.$route.path === "/voice/messages";
      },
    },
    methods: {
      emitFormData(data) {
        this.$emit("goToSelectUsers", data);
      },
      cancelModal() {
        if (this.fromView) {
          store.dispatch("modal/setFromView", false);
          if (store.getters["uistate/isInternational"]) {
            return store.dispatch("uistate/addContactFormData", {
              title: "",
              type: "view",
              fromSenderId: false,
            });
          } else {
            return store.dispatch("uistate/addContactFormData", {
              title: "",
              type: "view",
              fromSenderId: true,
            });
          }
        }
        this.$emit("cancelModal", {
          type: this.fromVoice ? "fromVoice" : "cancel",
        });
      },
      checkVoiceData() {
        let data = store.getters["uistate/contactForm"];
        if (data.message && data.message.isScheduleVoice) {
          delete data.message.isScheduleVoice;
          this.$emit("scheduleVoiceMessage", data.message);
        }
      },
      sendToGroup() {
        this.checkVoiceData();
        store.dispatch("uistate/addContactFormData", {
          title: this.$t(
            "dashboard.send_message.message_templates.send_message"
          ),
          type: "selectGroup",
          fromVoice: this.fromVoice,
          quickVoice: this.isFromQuickVoice ? true : false,
        });
        store.dispatch("modal/setMessagAction", "group");
        store.dispatch("uistate/setIsFromTemplateWithSpecial", false);
      },
      importContacts() {
        this.checkVoiceData();
        store.dispatch("uistate/addContactFormData", {
          title: this.$t("dashboard.send_message.sms.excel_sms.header"),
          type: "importContacts",
          fromVoice: this.fromVoice,
          quickVoice: this.isFromQuickVoice ? true : false,
        });
        store.dispatch("modal/setMessagAction", "excel");
      },
      sendToAnIndividual() {
        this.checkVoiceData();
        store.dispatch("uistate/addContactFormData", {
          title: this.$route.path.includes("voice")
            ? this.$t("dashboard.send_message.voice.quick_voice.title")
            : this.$t("dashboard.send_message.sms.quick_sms.title"),
          type: "selectIndividual",
          fromVoice: this.fromVoice,
          quickVoice: this.isFromQuickVoice ? true : false,
        });
        store.dispatch("modal/setMessagAction", "individual");
      },
      checkToggleShowOptions() {
        const getFormData = store.getters["modal/getFormData"];
        const pattern = /\[(fname|lname|fullname)\]/;
        if (pattern.exec(getFormData.body)) {
          this.showQuickSMS = false;
          store.dispatch("uistate/setIsFromTemplateWithSpecial", true);
        }
      },
      ConfirmationDialogVisible() {
        this.isConfirmationDialogVisible = true;
      },

      submitChange() {
        this.buttonLoading = true;
        this.$store
          .dispatch("voice/requestDid")
          .then(() => {
            this.$message({
              type: "success",
              message: this.$t("dashboard.send_message.sms.DID_success"),
            });
            this.isConfirmationDialogVisible = false;
            this.$emit("cancelModal");
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: this.$t("dashboard.send_message.sms.DID_error"),
            });
          })
          .finally(() => {
            this.buttonLoading = false;
          });
      },
    },
  };
</script>

<style scoped>
  .__header {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #484a4f;
    margin-top: 2em;
    margin-bottom: 0em;
  }

  ._btn {
    background: #f5f5f5;
    border-radius: 5px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #9e9ea7;
    outline: none;
    border: none;
    margin-top: 0.5em;
  }

  ._btn:hover {
    background-color: rgba(247, 146, 28, 0.1);
    color: #f7921c !important;
  }

  .activeBtn {
    background-color: rgba(247, 146, 28, 0.1);
    color: #f7921c !important;
  }
  .request_did {
    font-style: normal;
    font-weight: normal;
    font-size: 0.1em;
    line-height: 1.2;
    text-align: center;
    color: red;
    justify-content: center;
    margin-top: 3em;
    margin-bottom: 0em;
  }
  .confirmation-modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10000;
  }
  .heading {
    padding: 20px;
    border-radius: 5px;
    z-index: 10001;
    pointer-events: auto;
    position: relative;
  }
</style>

<template>
  <div
    class="row justify-content-center"
    style="padding-right: 1em; padding-left: 1em">
    <div class="col-md-12" v-if="displayType === 'template'">
      <el-form
        :model="formData"
        :rules="rules"
        ref="ruleForm"
        @submit.prevent.native="checkEnter">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="form_input_container">
              <el-form-item
                style="margin-top: 0px"
                :label="
                  $t(
                    'dashboard.send_message.sms.quick_sms.form.recipient.label'
                  )
                "
                prop="recipients">
                <el-input
                  type="textarea"
                  :rows="7"
                  autocomplete="off"
                  @change="handleInputChange($event, 'recipients')"
                  :placeholder="
                    $t(
                      'dashboard.send_message.sms.quick_sms.form.recipient.placeholder'
                    )
                  "
                  v-model="formData.recipients"></el-input>
              </el-form-item>
              <small class="text-danger" v-if="error">{{ error }}</small>
            </div>
            <el-form-item
              style="margin: px !important; padding: 0px !important"
              :label="
                $t(
                  'dashboard.send_message.sms.quick_sms.form.number_format.label'
                )
              "
              :required="true">
            </el-form-item>
          </div>
          <div
            class="col-md-12 d-flex __checkbock__container"
            style="margin-top: -40px">
            <el-form-item class="__input__left" id="" style="margin-top: 0px">
              <el-checkbox
                @change="handleCommaSeparatedChange"
                v-model="formData.commaSeparated"
                :label="
                  $t(
                    'dashboard.send_message.sms.quick_sms.form.number_format.comma_separated'
                  )
                ">
              </el-checkbox>
            </el-form-item>
            <el-form-item class="__input__left" style="margin-left: 1em">
              <el-checkbox
                @change="handleNewLinechange"
                v-model="formData.newLineSeparated"
                :label="
                  $t(
                    'dashboard.send_message.sms.quick_sms.form.number_format.new_line'
                  )
                ">
              </el-checkbox>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div class="col-md-12 mt-2" v-if="errors">
      <Alert :message="errors" :type="type" />
    </div>
    <div
      class="col-md-12 mt-3 mb-4 d-flex justify-content-between"
      style="width: 100%">
      <button class="___back__btn" @click="cancelModal">
        {{ $t("misc.button.back") }}
      </button>
      <div class="left_btn">
        <el-button
          style="padding-top: 1.1em; padding-bottom: 1.1em"
          type="primary"
          @click="sendMessageToAnIndividual('ruleForm', 'send')"
          v-if="!fromVoice">
          {{ $t("misc.button.next") }}
        </el-button>
        <button
          form="voice"
          v-if="fromVoice"
          class="schedule_message____"
          @click="sendMessageToAnIndividual('ruleForm', 'schedule')">
          {{ $t("dashboard.send_message.scheduled_messages.schedule_message") }}
        </button>
        <el-button
          style="padding-top: 1.1em; padding-bottom: 1.1em"
          type="primary"
          :loading="loading"
          @click="sendMessageToAnIndividual('ruleForm', 'sendVoiceMessage')"
          v-if="fromVoice">
          {{ $t("dashboard.send_message.sms.quick_sms.form.button") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from "jquery";
  import store from "@/state/store.js";

  export default {
    name: "create-message-template",
    props: {
      payloadData: {
        type: Object,
      },
    },
    data() {
      var validateTextMessages = (rule, value, callback) => {
        if (!this.formData.recipients) {
          callback(
            new Error(
              this.$t(
                "dashboard.send_message.sms.quick_sms.form.number_format.invalid"
              )
            )
          );
        } else {
          callback();
        }
      };
      return {
        messages: "",
        displayType: "template",
        error: "",
        errors: "",
        type: "alert-danger",
        modalType: "",
        loading: false,
        rules: {
          recipients: [
            {
              required: true,
              message: this.$t(
                "dashboard.send_message.sms.quick_sms.form.number_format.enter"
              ),
              trigger: ["change", "blur"],
            },
            {
              // allow only numbers and comma or new line or a + sign
              pattern: /^(\+|,|\n|[0-9])+/,
              message: this.$t(
                "dashboard.send_message.sms.quick_sms.form.number_format.invalid_number"
              ),
            },
          ],
          recipients_format: [
            { required: true, message: validateTextMessages },
          ],
        },
        formData: {
          recipients: "",
          recipients_format: "",
          commaSeparated: true,
          newLineSeparated: false,
          campaign_name: "",
          audio: [],
          voice_template_id: "",
        },
        sendMessage: {},
        sendMessageRules: {},
        fromVoice: false,
        loading: false,
      };
    },
    mounted() {
      let data = store.getters["uistate/contactForm"];
      let isFromvoice = store.getters["uistate/fromVoice"];
      const storeFormData = store.state.modal.formData;
      this.formData.recipients = storeFormData.recipients;
      if (storeFormData.recipients_format == "comma") {
        this.formData.commaSeparated = true;
        this.formData.newLineSeparated = false;
      }
      if (storeFormData.recipients_format == "new_line") {
        this.formData.commaSeparated = false;
        this.formData.newLineSeparated = true;
      }

      if (isFromvoice) {
        this.fromVoice = true;
      }
      if (this.payloadData) {
        this.formData.campaign_name = this.payloadData.campaign_name;
        this.formData.audio = this.payloadData.audio;
        this.formData.voice_template_id = this.payloadData.voice_template_id;
      }
    },
    computed: {
      messageForm() {
        return store.getters["uistate/contactForm"];
      },
    },
    methods: {
      handleInputChange(value, key) {
        store.dispatch("modal/updateSingleField", {
          key,
          value,
        });
      },
      scheduleMessage() {
        let newFormData = {
          recipients: this.formData.recipients,
          recipients_format: this.formData.recipients_format,
          voice_template_id: this.formData.voice_template_id,
        };
        this.$emit("scheduleVoiceMessage", newFormData);
        store.dispatch("uistate/addContactFormData", {
          title: this.$t("dashboard.send_message.voice.quick_voice.schedule"),
          type: "calender",
          voiceFrom: "quick",
        });
      },
      handleCommaSeparatedChange() {
        if (this.formData.commaSeparated) {
          return (this.formData.newLineSeparated = false);
        }
      },
      handleNewLinechange() {
        if (this.formData.newLineSeparated) {
          return (this.formData.commaSeparated = false);
        }
      },
      cancelModal() {
        if (store.getters["uistate/quickVoice"]) {
          return store.dispatch("uistate/addContactFormData", {
            title: this.$t("dashboard.send_message.select_sender_id"),
            type: "selectSenderId",
            quickVoice: true,
          });
        }
        this.$emit("backToSelect", {
          type: this.fromVoice ? "fromVoice" : "cancel",
        });
      },
      validateForm() {
        // validate selected format
        this.error = null;
        let splitNewLine = this.formData.recipients.split("\n");
        let splitComma = this.formData.recipients.split(",");
        this.formData.recipients_format = this.formData.commaSeparated
          ? "comma"
          : "new_line";
        if (splitNewLine.length > 1 || splitComma.length > 1) {
          if (this.formData.commaSeparated) {
            if (
              !this.formData.recipients.includes(",") ||
              this.formData.recipients.includes("\n")
            ) {
              this.error = this.$t(
                "dashboard.send_message.sms.quick_sms.form.number_format.invalid_comma"
              );

              return;
            } else {
              this.error = null;
            }
          }
          if (this.formData.newLineSeparated) {
            if (
              !this.formData.recipients.includes("\n") ||
              this.formData.recipients.includes(",")
            ) {
              this.error = this.error = this.$t(
                "dashboard.send_message.sms.quick_sms.form.number_format.invalid_new_line"
              );

              return;
            } else {
              this.error = null;
            }
          }
        }
        this.formData.recipients_format = this.formData.commaSeparated
          ? "comma"
          : "new_line";
      },
      send() {
        // prepare data
        let newFormData = {
          recipients: this.formData.recipients,
          recipients_format: this.formData.recipients_format,
        };

        if (
          this.formData.recipients.split(",").length > 500 ||
          this.formData.recipients.split(" ").length > 500
        ) {
          this.errors = this.$t(
            "dashboard.send_message.sms.quick_sms.form.number_format.min"
          );
          return;
        }
        this.$emit("backToTypeMessage", newFormData);
        this.$emit("individualSelectedData", newFormData);
      },
      sendVoiceMessage() {
        (this.loading = true), (this.errors = null);
        let formData = new FormData();
        formData.append("recipients_format", this.formData.recipients_format);
        formData.append("campaign_name", this.formData.campaign_name);
        formData.append("recipients", this.formData.recipients);
        if (this.formData.voice_template_id) {
          formData.append("voice_template_id", this.formData.voice_template_id);
        } else {
          formData.append("audio", this.formData.audio);
        }

        store
          .dispatch("voice/voiceQuick", formData)
          .then((res) => {
            if (res) {
              this.loading = false;
              res.data.from = "individual";
              this.$emit("saveVoiceMessage", res.data);
              this.$mixpanel.track("Send Quick Voice Message");
              this.$mixpanel.identify(store.getters["auth/currentUser"].phone);
              this.$mixpanel.people.increment("User Send Quick Voice Message");
              // for franco
              if (this.$store.getters["auth/currentUser"].country === "CI") {
                this.$mixpanelFranco.track("Send Quick Voice Message");
                this.$mixpanelFranco.identify(
                  store.getters["auth/currentUser"].phone
                );
                this.$mixpanelFranco.people.increment(
                  "User Send Quick Voice Message"
                );
              }
            }
          })
          .catch((err) => {
            this.loading = false;
            if (err?.response?.data == undefined) {
              this.errors = this.$t("misc.network_error");
            }
            if (err.response.status == 404) {
              if (
                err.response.data.message ===
                "validation failed. please try gain."
              ) {
                return (this.errors = Object.values(
                  err.response.data.data
                ).toString());
              }
              this.errors = err.response.data.message;
            }
          });
      },
      sendMessageToAnIndividual(formName, type) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.error = null;
            if (
              this.formData.recipients.split("\n").length > 1 ||
              this.formData.recipients.split(" ").length
            ) {
              if (
                this.formData.commaSeparated &&
                this.formData.recipients.includes(" ")
              ) {
                this.error = this.$t(
                  "dashboard.send_message.sms.quick_sms.form.number_format.invalid_comma"
                );
              } else if (
                this.formData.newLineSeparated &&
                this.formData.recipients.includes(",")
              ) {
                this.error = this.$t(
                  "dashboard.send_message.sms.quick_sms.form.number_format.invalid_new_line"
                );
              } else {
                this.error = null;
              }
            }
            if (this.error) return;
            this.formData.recipients_format = this.formData.commaSeparated
              ? "comma"
              : "new_line";
            this.handleInputChange(
              this.formData.recipients_format,
              "recipients_format"
            );
            if (type == "send") {
              return this.send();
            }
            if (type == "sendVoiceMessage") {
              return this.sendVoiceMessage();
            }
            if (type == "schedule") {
              return this.scheduleMessage();
            }
          } else {
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped>
  ._select_text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: #484a4f;
  }
</style>

<template>
  <div>
    <div style="max-height: 60vh; overflow-y: auto; padding-inline: 1rem">
      <el-form
        @submit.prevent
        label-position="top"
        :model="formData"
        :rules="rules"
        ref="ruleForm"
        id="translation-form">
        <div class="form-container">
          <div class="form-item">
            <el-form-item
              :label="$t('ai.translation.original_message')"
              prop="originalMessage">
              <el-input
                type="textarea"
                :placeholder="$t('ai.translation.original_message')"
                maxlength="459"
                v-model="formData.originalMessage"
                show-word-limit
                resize="none"
                :autosize="{ minRows: 3, maxRows: 6 }"
                @input="handleChange">
              </el-input>

              <!-- <small style="opacity: 0.5">{{
                $t("ai.translation.updated_message")
              }}</small> -->
            </el-form-item>
            <el-checkbox v-model="changeOriginalLanguage" style="margin: 0">
              <small>{{
                $t("ai.translation.change_original_language", {
                  language: selectedLanguageLabel,
                })
              }}</small>
            </el-checkbox>
          </div>
          <div class="form-item target-languages">
            <el-form-item
              v-if="changeOriginalLanguage"
              :label="$t('ai.translation.confirm_original_language')"
              prop="originalLanguage">
              <el-select
                :placeholder="$t('ai.translation.confirm_original_language')"
                v-model="formData.originalLanguage">
                <el-option
                  v-for="item in availableLanguages"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="$t('ai.translation.target_language')"
              prop="targetLanguage">
              <el-select
                :placeholder="$t('ai.translation.select_language')"
                v-model="formData.targetLanguage">
                <el-option
                  v-for="item in availableTargetLanguages"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-item">
            <el-form-item :label="$t('ai.translation.translated_message')">
              <el-input
                type="textarea"
                :placeholder="$t('ai.translation.your_translation')"
                maxlength="459"
                show-word-limit
                resize="none"
                :rows="6"
                :autosize="{ minRows: 5, maxRows: 6 }"
                v-model="translatedMessage"
                @input="handleChange"
                :disabled="isStreaming || translatedMessage.length === 0">
              </el-input>
              <transition name="el-fade-in-linear">
                <small v-if="isStreaming" style="margin: 0; padding: 0">{{
                  $t("ai.translation.translation_processing")
                }}</small>
              </transition>
            </el-form-item>
            <messageInfo :message="finalTranslationMessage"></messageInfo>
            <!-- <div v-if="!isCreateTemplate()">
              <el-checkbox
                v-model="formData.saveAsTemplate"
                :disabled="isStreaming || isLoading">
                {{ $t("ai.translation.save_as_template") }}
              </el-checkbox>
            </div> -->
          </div>
        </div>
      </el-form>
    </div>
    <footer
      style="
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 2rem;
        gap: 1rem;
      ">
      <el-button
        :type="translatedMessage ? 'danger' : 'text'"
        @click="$emit('close')"
        :disabled="isStreaming || isLoading || isSavingTemplate">
        <transition name="el-zoom-in-top">
          <span v-if="translatedMessage">
            {{ $t("misc.button.save") }} {{ $t("misc.button.and") }}
            {{ $t("misc.button.close") }}
          </span>
          <span v-else>{{ $t("misc.button.close") }}</span>
        </transition>
      </el-button>

      <div>
        <el-button
          type="primary"
          @click="translateText"
          :disabled="isStreaming || isLoading || isSavingTemplate"
          :loading="isLoading"
          form="translation-form">
          {{ $t("ai.translation.translate") }}
        </el-button>
      </div>
      <div v-if="currentLoginType === 'sms'">
        <el-button
          type="primary"
          @click="$emit('close')"
          :disabled="
            isStreaming || isLoading || isSavingTemplate || !translatedMessage
          "
          form="translation-form">
          {{ $t("dashboard.send_message.sms.quick_sms.form.button") }}
        </el-button>
      </div>
    </footer>
  </div>
</template>

<script>
  import messageInfo from "../messageInfo.vue";
  import loginType from "../../../../helpers/loginType";
  /**
   * @typedef {Object} Campaign
   * @property {string} campaign_name - The name of the campaign.
   * @property {string} message - The message content.
   * @property {number} sender_id - The ID of the sender.
   * @property {number} perMessage - The per message value.
   * @property {string} campaign_id - The ID of the campaign.
   * @property {string} purpose - The purpose of the campaign.
   * @property {string} sender - The sender information.
   * @property {Array} headers - The list of headers.
   * @property {string} header - A single header.
   * @property {string} messageWithTitle - The message with title.
   * @property {string} route_name - The route name.
   * @property {string} encoding - The encoding type.
   */
  export default {
    name: "AITranslation",
    inject: ["campaign", "isCreateTemplate"],
    emits: ["close", "move-to-speech"],
    components: {
      messageInfo,
    },
    data() {
      return {
        currentLoginType: loginType(),
        availableLanguages: [
          { label: "English", value: "en" },
          { label: "Twi", value: "tw" },
          { label: "Ga", value: "gaa" },
          { label: "Ewe", value: "ee" },
          { label: "Fante", value: "fat" },
          { label: "Dagbani", value: "dag" },
          { label: "Gurene", value: "gur" },
          { label: "Yoruba", value: "yo" },
          { label: "Kikuyu", value: "ki" },
          { label: "Luo", value: "luo" },
          { label: "Kimeru", value: "mer" },
        ],
        formData: {
          originalMessage: "",
          originalLanguage: "en",
          targetLanguage: "",
          saveAsTemplate: true,
        },
        translatedMessage: "",
        isStreaming: false,
        isLoading: false,
        isSavingTemplate: false,
        changeOriginalLanguage: false,
        rules: {
          originalMessage: [
            {
              required: true,
              message: this.$t("ai.translation.form.original_message.required"),
              trigger: "blur",
            },
            {
              min: 1,
              message: this.$t("ai.translation.form.original_message.required"),
              trigger: "blur",
            },
            {
              max: 459,
              message: this.$t("ai.translation.form.original_message.max"),
              trigger: "blur",
            },
          ],
          originalLanguage: [
            {
              required: true,
              message: this.$t(
                "ai.translation.form.original_language.required"
              ),
              trigger: "change",
            },
          ],
          targetLanguage: [
            {
              required: true,
              message: this.$t("ai.translation.form.target_language.required"),
              trigger: "change",
            },
          ],
        },
      };
    },
    computed: {
      availableTargetLanguages() {
        return this.availableLanguages.filter(
          (lang) => lang.value !== this.formData.originalLanguage
        );
      },
      selectedLanguageLabel() {
        return this.availableLanguages.find(
          (lang) => lang.value === this.formData.originalLanguage
        ).label;
      },
      finalTranslationMessage() {
        return this.translatedMessage;
      },
    },
    created() {
      this.formData.originalMessage =
        this.campaign().message || this.campaign().body;
    },
    watch: {
      campaign: {
        handler() {
          this.formData.originalMessage =
            this.campaign().message || this.campaign().body;
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      handleChange($event) {
        this.formData.originalMessage = $event;
        this.$store.dispatch("ai/setTextOutput", $event);
      },
      /** mNotify: Add in chatgpt text streaming
       * randomDev: We got chatgpt text streaming at home
       * ChatGPT text streaming at home:
       */
      streamInText(content) {
        if (!content) return;
        this.copyToClipboard(content);
        this.translatedMessage = "";
        this.isStreaming = true;
        const splitContent = content.split("");
        splitContent.forEach((word, index) => {
          setTimeout(() => {
            this.translatedMessage += word + "";
          }, 20 * index);
        });
        setTimeout(() => {
          this.isStreaming = false;
        }, 20 * splitContent.length);
        setTimeout(() => {
          if (this.currentLoginType !== "sms") {
            this.$emit("move-to-speech");
          }
        }, 20 * splitContent.length + 500);
      },

      /**
       * Translates text using the provided payload.
       * @returns {Promise<TranslationResponse>} The translated text.
       */
      async translateText() {
        // validate the form
        try {
          await this.$refs.ruleForm.validate();
          // shape the data
        } catch (error) {
          return;
        }
        const payload = {
          in: this.formData.originalMessage,
          lang:
            this.formData.originalLanguage + "-" + this.formData.targetLanguage,
        };
        try {
          this.isLoading = true;
          /**
           * @import {TranslationResponse} from '@/state/modules/ai'
           */

          /**
           * @type {TranslationResponse}
           */
          const { data } = await this.$store.dispatch(
            "ai/translateText",
            payload
          );
          this.$message.success(this.$t("ai.translation.success_message"));
          this.streamInText(data);
          if (this.formData.saveAsTemplate) {
            await this.saveAsTemplate(data);
          }
        } catch (error) {
          this.$message.error(this.$t("ai.translation.error_message"));
        } finally {
          this.isLoading = false;
        }
      },
      /**
       *
       * @param {string} translatedMessage - The data to save as a template.
       */
      async saveAsTemplate(translatedMessage) {
        try {
          this.isSavingTemplate = true;
          const payload = {
            title:
              this.campaign().campaign_name /**@type {Campaign} */ ||
              translatedMessage,
            body: translatedMessage,
            type: 1,
            selected_countries: "",
          };
          await this.$store.dispatch("campaigns/saveTemplate", payload);
          this.$notify({
            title: this.$t("misc.toast.success"),
            message: this.$t(
              "dashboard.send_message.message_templates.successful_save"
            ),
            type: "success",
          });
          this.$store.dispatch("campaigns/fetchTemplate");
        } catch (error) {
          this.$notify({
            title: this.$t("misc.toast.error"),
            message: this.$t(
              "dashboard.send_message.message_templates.error_save"
            ),
            type: "error",
          });
        } finally {
          this.isSavingTemplate = false;
        }
      },
      copyToClipboard(text) {
        if ("clipboard" in navigator) {
          navigator.clipboard.writeText(text).then(() => {
            this.$message.success("Copied to clipboard");
          });
        }
      },
    },
  };
</script>

<style scoped>
  * >>> .el-form-item__label {
    padding: 0;
    margin: 0;
  }

  .target-languages {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }

  .target-languages >>> div {
    width: 100%;
  }

  .form-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
  }
</style>

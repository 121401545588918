<template>
  <div
    style="display: flex; flex-wrap: wrap; justify-content: space-between"
    class="text_description">
    <span>
      {{ messageInfo.length }}
      {{
        $t(
          "dashboard.send_message.message_templates.form.message.characters_entered"
        )
      }}</span
    >
    <span v-show="messageInfo.length">
      {{ messageInfo.messages }}
      {{
        $t(
          "dashboard.send_message.message_templates.form.message.per_recipient"
        )
      }}</span
    >
    <div v-show="messageInfo.length">
      <span>
        {{
          $t(
            "dashboard.send_message.message_templates.form.message.encoding_type"
          )
        }}</span
      >
      <span
        :style="{
          color: messageInfo.encoding === 'UTF16' ? '#FF0000' : '#36d962',
        }">
        {{ messageInfo.encoding }}
      </span>
    </div>
  </div>
</template>

<script>
  import ASCIIFolder from "fold-to-ascii";
  import get_length_and_segment from "@/helpers/character_counter";
  export default {
    name: "messageInfo",
    emits: ["GSMOnly"],
    props: {
      message: {
        type: String,
        required: true,
      },
      gsmOnly: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    computed: {
      asciiMessage() {
        return ASCIIFolder.foldReplacing(this.message, "");
      },
      messageInfo() {
        if (this.gsmOnly) {
          this.$emit("GSMOnly", this.asciiMessage);
        }
        return get_length_and_segment(this.message);
      },
    },
  };
</script>

<style scoped>
  .text_description {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484a4f;
  }
</style>

<template>
  <RenderlessCalendar
    class="notranslate"
    :locale="currentLocale"
    v-slot="{
      getModifiers,
      selectedDates,
      currentYear,
      prevPage,
      nextPage,
      weekDayNames,
      onDateSelect,
      monthNames,
      calendar,
    }"
    mode="single"
    :default-selected-dates="[defaultDateFromApi ? defaultDateFromApi : '']"
    @onDateChange="handleDateChange">
    <div class="root">
      <!-- TODO:  :default-selected-dates="['2021-10-14']" FOR UPDATE PASS USER DATE TO DEFAULT SELECTED DATE -->
      <div
        v-for="view in calendar"
        :key="`${view.month}-${view.year}`"
        class="calendar"
        :data-date="selectedDates[0] && selectedDates[0].formatted">
        <div class="calendar__header">
          <button class="calendar__month-btn" @click="prevPage">
            <img src="../../assets/arrow__forward.svg" alt="img" />
          </button>
          <span class="calendar__title" style="text-transform: capitalize"
            >{{ monthNames[view.month].full }} {{ view.year }}</span
          >
          <button class="calendar__month-btn" @click="nextPage">
            <img src="../../assets/__arrow__back.svg" alt="img" />
          </button>
        </div>

        <div class="calendar__weeks">
          <span
            v-for="day in weekDayNames"
            :key="day.short"
            class="calendar__week-day"
            >{{ day.short }}</span
          >
        </div>

        <div class="calendar__body">
          <CalendarCell
            v-for="date in view.dates"
            :key="date.ms"
            v-bind="getModifiers(date)"
            @click.native="onDateSelect(date)"
            :date="date" />
        </div>
      </div>
    </div>
  </RenderlessCalendar>
</template>

<script>
  import { RenderlessCalendar } from "vue-renderless-calendar/lib/index";
  import CalendarCell from "./CalenderCell.vue";
  export default {
    name: "Calendar",
    components: {
      CalendarCell,
      RenderlessCalendar,
    },
    props: {
      defaultDateFromApi: {
        required: false,
      },
    },
    methods: {
      handleDateChange(payload) {
        this.$emit("handleDate", payload);
      },
      log(something) {},
    },
    computed: {
      currentLocale() {
        return this.$store.getters["language/currentLanguage"];
      },
    },
  };
</script>

<style scoped lang="scss">
  $cell-width: 43px;
  $cell-height: 40px;
  $light-gray: #f7f7f9;

  .root {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: white;
    border-radius: 5px;
  }

  .calendar {
    position: relative;
    z-index: 2;
    width: calc(#{$cell-width} * 7);
    padding: 8px;

    &__header {
      padding: 20px 0;
      display: flex;
      justify-content: space-between;
      border: none;
    }

    &__title {
      font-style: normal;
      font-weight: bolder;
      font-size: 16px;
      line-height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      color: #484a4f;
    }

    &__weeks {
      display: flex;
      justify-content: center;
      text-align: center;
    }

    &__week-day {
      display: inline-block;
      width: $cell-width;
      text-transform: uppercase;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #484a4f;
    }

    &__body {
      max-width: calc(#{$cell-width} * 7);
      min-width: calc(#{$cell-width} * 7);
      justify-content: flex-start;
      display: flex;
      flex-wrap: wrap;
    }

    &__month-btn {
      width: 40.3px;
      height: 40.3px;
      background-color: #fff;
      outline: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 1.34346px 1.34346px rgba(0, 14, 51, 0.05);
      border-radius: 20.15px;

      &:first-of-type {
        border-top-left-radius: 20.15px;
        border-bottom-left-radius: 20.15px;
      }
      &:last-of-type {
        border-top-right-radius: 20.15px;
        border-bottom-right-radius: 20.15px;
      }

      &:focus {
        outline: none;
      }
    }
  }
</style>

<template>         
    <div >
        <div class="row justify-content-center align-items-center"
            style="padding-right:1em; padding-left:1em;"
            v-if="success">
            <successMessage :message="success" />
            <button @click="hideMainModal" class="__close__success__modal">Close</button>
        </div>
        <div v-else class="row justify-content-center align-items-center"
            style="padding-right:1em; padding-left:1em; padding-top:3em;">
            <div class="continue_edit_header pb-2">
                <div class="mb-4">
                    <img src="../../../assets/Delete.svg" height="33px" width="31px" alt="delete icon">
                </div>
                <h3 v-if="!payload.deleteType" class="continue_edit_header_title">
                    Deleting Template will remove it permanently. 
                </h3>
                <h3 v-else class="continue_edit_header_title">
                    {{payload.deleteMessage}}
                </h3>
            </div>
            <div class="col-md-12" style="padding-left:3em; padding-right:3em;">
                <Alert v-if="errors" :message="errors"  :type="type" />
            </div>
        </div>
        <div class="modal__footer__btns" v-if="!success">
            <div class="deleteWrapper">
                <el-button
                    @click="deleteMessage"
                    style="margin-right:1.5em;padding-right:2em; padding-left:2em" 
                    :loading="loading" type="danger">
                    Delete
                </el-button>
                <el-button  style="margin-right:1.5em;padding-right:2em; padding-left:2em" 
                    @click="hideMainModal" type="primary">
                    Cancel
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery'
import store from '@/state/store.js'
export default {
    name:'message-delete-edit',
    props: {
        payload: {
            required: true,
            type: Object
        }
    },
    data() {
        return {
            loading: false,
            errors: '',
            fromVoice: false,
            type:'alert-danger',
            success: ''
        }
    },
    mounted() {

    },
    methods: {
        deleteMessage(){
            this.loading = true
            let data = {id: this.payload.selectedMessage.id}
            let payloadMessage = store.getters['uistate/contactForm']
            //delete user  key
            if(this.payload.deleteType && this.payload.deleteType == "_deleteUser"){
                let payloadData = {
                    userDeleteType: this.payload.userDeleteType,
                    data: {
                        user_id: this.payload.selectedMessage.id,
                    },
                    index: this.payload.selectedKey,
                    from: this.payload.from
                }
                return store.dispatch('admin/users/deleteUserAccount', payloadData)
                .then( res => {
                        this.loading = false
                        this.success = 'User deleted successfully'
                        this.$emit('deleteSuccess',{message:'User deleted successfully', key:this.payload.selectedKey})
                }).catch(err => {
                    this.loading = false
                    if(err?.response?.data == undefined) {
                        this.errors = "Network error try again."
                    }
                })
            }
            //delete V1 key
            if(this.payload.deleteType && this.payload.deleteType == "_deleteApiV1Key"){
                let data = {id: this.payload.selectedMessage.id}
                return store.dispatch('developer/deleteApiV1', data)
                .then( data => {
                    if(data) {
                        this.loading = false
                        this.success = data.message
                        this.$emit('deleteSuccess',{message:data.message, key:this.payload.selectedKey})
                    }
                }).catch(err => {
                    this.loading = false
                    if(err?.response?.data == undefined) {
                        this.errors = "Network error try again."
                    }
                })
            }
            //delete V2 key
            if(this.payload.deleteType && this.payload.deleteType == "_deleteApiV2Key"){
                let data = {
                    id: this.payload.selectedMessage.id,
                    key: this.payload.selectedMessage.selectedKey
                }
                return store.dispatch('developer/deleteApiV2', data)
                .then( data => {
                    if(data) {
                        this.loading = false
                        this.success = data.message
                        this.$emit('deleteSuccess',{message:data.message, key:this.payload.selectedKey})
                    }
                }).catch(err => {
                    this.loading = false
                    if(err?.response?.data == undefined) {
                        this.errors = "Network error try again."
                    }
                })
            }
            //delete scheduled voice message
            if(this.payload.deleteType && this.payload.deleteType == "scheduled__voice"){
                let data = {id: this.payload.selectedMessage.campaign_id}
                return store.dispatch('voice/deleteVoiceMessage', data)
                .then( res => {
                    if(res) {
                        this.loading = false
                        this.success = res.data.message
                        this.$emit('deleteSuccess',{message:res.data.message, key:this.payload.selectedKey})
                    }
                }).catch(err => {
                    this.loading = false
                    if(err?.response?.data == undefined) {
                        this.errors = "Network error try again."
                    }
                })
            }
            //delete voice message 
            if(this.payload.deleteType && this.payload.deleteType == "voice" || payloadMessage.message && payloadMessage.message.deleteType == 'voice'){
                let data = {
                    voice_note_id: this.payload.selectedMessage.voice_note_id || payloadMessage.message && payloadMessage.message.selectedMessage.voice_note_id,
                    key: this.payload.selectedKey ||  payloadMessage.message && payloadMessage.message.selectedKey,
                }
                return store.dispatch('voice/deleteVoiceNote', data)
                .then( res => {
                    if(res) {
                        this.loading = false
                        this.success = res.data.message
                        this.$emit('deleteSuccess',{message:res.data.message, key:this.payload.selectedKey})
                    }
                }).catch(err => {
                    this.loading = false
                    if(err?.response?.data == undefined) {
                        this.errors = "Network error try again."
                    }
                })
            }
            // delete scheduled messages
            if(this.payload.deleteType && this.payload.deleteType == "__scheduled"){
                let data = {id: this.payload.selectedMessage.campaign_id}
                return store.dispatch('campaigns/deleteScheduledMessages', data)
                .then( res => {
                    if(res) {
                        this.loading = false
                        this.success = res.data.message
                        this.$emit('deleteSuccess',{message:res.data.message, key:this.payload.selectedKey})
                    }
                }).catch(err => {
                    this.loading = false
                    if(err?.response?.data == undefined) {
                        this.errors = "Network error try again."
                    }
                })
            }
            // delete template messages
            store.dispatch('campaigns/deleteTemplate', data)
                .then( res => {
                    if(res) {
                        this.loading = false
                        this.success = res.data.message
                        this.$emit('deleteSuccess',{message:res.data.message, key:this.payload.selectedKey})
                    }
                }).catch(err => {
                    this.loading = false
                    if(err?.response?.data == undefined) {
                        this.errors = "Network error try again."
                    }
                })
        },
        hideMainModal(){
            this.$emit('closeModal')
            this.success = ''
            this.errors = ''
            this.loading = false
        },
    }
}
</script>

<style scoped>

.modal__footer__btns{
    display: flex;
    margin: 2.5em 0;
    justify-content: space-between;
}
.deleteWrapper{
    width:100%;
    display:flex;
    justify-content:center;
    align-items: center;
}
.continue_edit_header{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 2em;
    margin-left: 2em;
}
.continue_edit_header_title{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #575761;
}
</style>
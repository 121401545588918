<template>
  <div class="row justify-content-center align-items-center" style="width:100%; margin:0px; padding:0px;">
      <div class="col-md-12" >
            <div id="chart" class="" ref="chart" >
                <apexcharts type="donut" width="100%" height="100%" :options="chartOptions" :series="chartOptions.series"></apexcharts>
            </div>
            <div class="bottom_description">
                <div class="">
                    <p class="total_credit_used">
                        total credit used
                    </p>
                    <p class="__number">
                        <strong>14,559</strong>
                    </p>
                </div>
                <div class="">
                    <p class="__total__contacts">
                        total contacts
                    </p>
                    <p class="__number">
                    <strong> 14,559</strong>
                    </p>
                </div>
            </div>
            <div class="__view__more d-flex justify-content-center align-items-center pb-4">
                <button>View more</button>
            </div>
      </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    props: {
    chartDataOptions: {
            required: true
        }
    },
    components: {
      apexcharts: VueApexCharts,
    },
    data: function() {
      return {
        // series1: [2, 4, 5, 6],
        // labels1: ['a', 'b', 'c', 'd'],
      }
    },
    methods: {

    },
    computed: {
      chartOptions: function() {
        return {
            colors: ['#36D962', '#30A1F0', '#F0E930', '#F7921C'],
            legend: {
                fontSize: '14px',
                fontWeight: 400,
                floating:false,
                offsetX: 0,
                offsetY: 0,
                height:300,
                // position: 'right',
                itemMargin: {
                    horizontal: 5,
                    vertical: 20
                },
                formatter: function(seriesName, opts) {
                    let total = 0;
                    let series = opts.w.globals.series
                    let singleSeries = opts.w.globals.series[opts.seriesIndex]
                    for(let i = 0; i < series.length; i++ ) {
                        total += series[i]
                    }
                    let perCentage = parseInt(( singleSeries / total * 100 ))
                    return '<div style="display:inline-block;"">' + '<div style="width:350px;padding-left:1rem;">' + '<strong style="padding-right:3rem;">' + singleSeries + '</strong>' + '<span styple="">' + seriesName + '</span>' + '<span style="float:right!important">' + perCentage + '%' + '</span>' + '</div>' + '</div>'
                }
            },
            dataLabels: {
                enabled: false,
            },
            labels: ['Delivered', 'Submitted', 'Expired', 'Undelivered'],
            plotOptions: {
                pie: {
                    size: 200,
                    expandOnClick: false,
                    donut: {
                    size: "88%",
                    background: 'transparent',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            offsetY: -10
                        },
                        value: {
                            show: true,
                            fontSize: '16px',
                            color: "#575761",
                            fontStyle: "normal",
                            fontWeight: "bold",
                            fontSize: "29px",
                            lineHeight: "32px",
                            formatter: function (val) {
                                return val
                            }
                        },
                        total: {
                            show: true,
                            label: 'Total Messages',
                            color: '#575761',
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: "12px",
                            lineHeight: "13px",
                            textTransform:'capitalize',
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a, b) => {
                                return  a + b
                                }, 0)
                            }
                        }
                    }
                    }
                }
            },
            series: [400, 100, 300, 500],
            responsive: [
                {
                breakpoint: 1000,
                options: {
                    legend: {
                        position: "bottom",
                        height:220,
                        itemMargin: {
                            horizontal:2,
                            vertical:0
                    },
                    }
                }
                }
            ]
            }
        },
    },
}
</script>

<style scoped>
.bottom_description{
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-top: 1px solid #F3F3F4;
    padding-top: 2em;
    padding-bottom: 4em;
    padding-left: 20%;
    padding-right: 20%;
}
.total_credit_used,.__total__contacts{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-transform: capitalize;
    color: #484A4F;
}
button{
    outline:none;
    border:none;
    background: #F5F5F5;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    color: #9E9EA7;
    height: 40px;
    width:142.64px;
}
#chart {
  height: 250px;
  margin-top:4em;
  margin-bottom: 3em;
}
div.legend-info{
    display: none!important
}
@media screen and (max-width:1000px) {
    #chart {
        height: 550px;
        margin-top:1em;
        margin-bottom: 0px!important;
        margin-left:-40px;
    }
    .legend-info{
        display: none;
    }
}
</style>